import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import { ShadowDomWrapper } from './ShadowDomWrapper';
import './Browser.css';

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: '#ffffff';
  display: flex;
  justify-content: center;
`;

const ZoomContainer = styled.div`
  transform: scale(${(props) => props.zoom});
  transform-origin: top center;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: '#ffffff';
`;

export const Browser = forwardRef(({ htmlContent = '' }, ref) => {
  const [zoom, setZoom] = useState(1);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const contentRef = useRef(null);
  const shadowDomRef = useRef();

  useImperativeHandle(ref, () => ({
    getBrowserHtml: () => {
      if (shadowDomRef.current) {
        return shadowDomRef.current.getShadowDomHtml();
      }
    }
  }));

  useEffect(() => {
    if (contentRef.current) {
      const { offsetWidth, offsetHeight } = contentRef.current;
      setContainerSize({
        width: offsetWidth,
        height: offsetHeight,
      });
      console.log('Container size set:', { width: offsetWidth, height: offsetHeight });
    }
  }, [zoom]);


  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(0.1, prevZoom - 0.1));
  };

  const scopedHtmlContent = `
    <div class="scoped-content">${htmlContent}</div>
  `;

  return (
    <Card className="console-card">
      {/* <CardContent>
        <Typography variant="h6" className="console-text">{title}</Typography>
      </CardContent> */}

      <ScrollableContent ref={contentRef}>
        <ZoomContainer zoom={zoom} width={containerSize.width / zoom} height={containerSize.height / zoom}>
          <ShadowDomWrapper
            ref={shadowDomRef}
            htmlContent={scopedHtmlContent}
          />
        </ZoomContainer>
      </ScrollableContent>
      <div className="zoom-controls">
        <button onClick={handleZoomIn}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.5355 11H7.46447" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 7.46448V14.5355" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 21L16.7 16.7" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
          </svg> </button>
        <button onClick={handleZoomOut}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.5355 11H7.46447" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 21L16.7 16.7" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    </Card>
  );
});