import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Logo from '../../assets/logo-white.png';
import './Sidebar.css';
import { useAuth } from '../../context/AuthContext';

export const Sidebar = () => {
    const { client } = useAuth();
    const [showAccount, setShowAccount] = useState(true);

    const toggleAccount = () => {
        setShowAccount(!showAccount);
    };

    return (
        <div id="hs-offcanvas-example" className="sidebar" role="dialog" aria-label="Sidebar">
            <div className="sidebar-brand-container">
                <NavLink to="/" className="sidebar-brand">
                    <img src={Logo} alt="Logo" />
                </NavLink>
                Data Shark
            </div>
            <nav className="sidebar-nav">
                <div className="sidebar-top">
                </div>
                <div className="sidebar-bottom">
                    <div className="sidebar-section">
                        <NavLink to="/login" className="sidebar-link">
                            <LogoutIcon style={{ fontSize: 20 }} />
                            Logout
                        </NavLink>
                        {(client === 'dev') && (
                            <NavLink to="/cladmin"
                                className={({ isActive }) =>
                                    `sidebar-link ${isActive ? 'sidebar-link-active' : ''}`}>
                                <PeopleAltOutlinedIcon style={{ fontSize: 20 }} />
                                CL-Admin
                            </NavLink>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};
