// src/components/GeneralPage/GeneralPage.js

import React from 'react';
import './GeneralPage.css';
import { useAuth } from '../../context/AuthContext';

const GeneralPage = () => {
    const {
        apiMode,
        setGlobalApiModeDirectly, // Use the new function
        endpointModes,
        toggleEndpointMode
    } = useAuth();

    // Determine the global toggle state based on apiMode
    const globalToggle = apiMode === 'local';

    // Convert endpointModes object to an array for easier mapping
    const endpoints = Object.keys(endpointModes).map(name => ({
        name,
        useAWS: endpointModes[name] === 'local'
    }));

    // Handle global toggle based on the checkbox's checked state
    const handleGlobalToggle = (e) => {
        const newMode = e.target.checked ? 'local' : 'aws';
        setGlobalApiModeDirectly(newMode);
    };

    return (
        <div className="general-page">
            <div className="endpoints-container">
                {/* Global Toggle */}
                <div className="endpoint-items global-toggle">
                    <h1>Endpoints</h1>
                    <div className="endpoint-toggle">
                        <span>AWS</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={globalToggle}
                                onChange={handleGlobalToggle} // Use the new handler
                            />
                            <span className="slider"></span>
                        </label>
                        <span style={{ color: globalToggle ? "#84DA89" : "#fff" }}>Local</span>
                    </div>
                </div>

                {/* Individual Endpoint Toggles */}
                {endpoints.map((endpoint, index) => (
                    <div key={index} className="endpoint-item">
                        <div
                            style={{ color: endpoint.useAWS ? "#84DA89" : "#fff" }}
                            className="endpoint-item-title"
                        >
                            {endpoint.useAWS ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                                    <path d="M12 1.17102L17 6.17102L12 11.171M6 11.171L1 6.17102L6 1.17102" stroke="#84DA89" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                                    <path d="M12 1.5L17 6.5L12 11.5M6 11.5L1 6.5L6 1.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            )}
                            <span>{endpoint.name}</span>
                        </div>
                        <div className="endpoint-toggle">
                            <span>AWS</span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={endpoint.useAWS}
                                    onChange={() => toggleEndpointMode(endpoint.name)}
                                />
                                <span className="slider"></span>
                            </label>
                            <span style={{ color: endpoint.useAWS ? "#84DA89" : "#fff" }}>Local</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GeneralPage;
