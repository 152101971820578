import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './SignUp.css'; // Import the CSS file
import Logo from '../assets/logo.png';
import * as Yup from 'yup';
import { useFormik } from 'formik';



const SignUp = () => {
  const [loading, setLoading] = useState(false);  // Add loading state
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated, endpointModes } = useAuth();
  

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('First Name is required'),
    lastName: Yup.string()
      .required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const isFormEmpty = formik.values.email === '' || 
    formik.values.firstName === '' || 
    formik.values.lastName === '' || 
    formik.values.password === '';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const endpointName = 'users/signup';
      const signupMode = endpointModes[endpointName] || 'local';

      console.log(`API Mode for ${endpointName}:`, signupMode);

      const baseURL = signupMode === 'local' 
        ? 'http://127.0.0.1:8000/' 
        : 'http://3.101.21.162:8000/';

      const urlToUse = `${baseURL}${endpointName}`;

      const response = await fetch(urlToUse, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          'email': formik.values.email, 
          'first_name': formik.values.firstName, 
          'last_name': formik.values.lastName, 
          'password': formik.values.password 
        }),
      });
      console.log(response.status)
      if (response.ok) {
        const data = await response.json();
        console.log("fetched client: ", data['client']);
        // alert('Signup successful!');
        // setIsAuthenticated(true);
        // navigate('/');  // Redirect to the scrape page
      } else {
        const errorData = await response.json();
        console.error('Signup failed!', errorData);
        alert('Signup failed!');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      alert('Signup failed due to network error!');
    } finally {
      setLoading(false);
    }
  };

  const handleLogIn = () => {
    navigate('/login');
  };

  return (
    <div className="container">
      <div className='title-container'>
        <img src={Logo} alt='logo' />
        Data Shark
      </div>
      <h1>Signup for our beta to start scraping today</h1>
      <form onSubmit={handleSubmit}>
        <div className="name-container">
          <div>
            <label>First Name *</label>
            <input
              type="first_name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} // Trigger validation onBlur
              className={formik.touched.firstName && formik.errors.firstName ? 'input-error' : ''}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error">{formik.errors.firstName}</div>
            ) : null}
          </div>

          <div>
            <label>Last Name *</label>
            <input
              type="last_name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.lastName && formik.errors.lastName ? 'input-error' : ''}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error">{formik.errors.lastName}</div>
            ) : null}
          </div>
        </div>

        <div className='email-container'>
          <label>Email *</label>
          <input
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={formik.touched.email && formik.errors.email ? 'input-error' : ''}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className='password-container'>
          <label>Password *</label>
          <input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={formik.touched.password && formik.errors.password ? 'input-error' : ''}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="error">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className='submit-container'>
          <p>By clicking this button, you agree to our Terms of Service and Privacy Policy</p>
          <button className='submit-button' disabled={!formik.isValid || isFormEmpty || loading} onClick={handleSubmit}>
            {loading ? 'Creating Account...' : 'Create Account'}
          </button>
        </div>
      </form>
      <div className='login-container'>
        Already have an account?
        <button className='login-button' onClick={handleLogIn}>Log In!</button>
      </div>
    </div>
  );
};

export default SignUp;
