// Header.js
import React from 'react';
import './Header.css';

export const Header = () => {
  return (
    <div className="header-container">
      <div>Start scraping ...</div>
    </div>
  );
};