// src/components/URLInput/URLInput.js

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './URLInput.css';
import { useAuth } from '../../../context/AuthContext';

export const URLInput = forwardRef(({ onFetch }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const { getEndpointURL, getFastApiURL } = useAuth(); // Destructure endpointModes from AuthContext

  useImperativeHandle(ref, () => ({
    getUrl: () => inputValue,
  }));

  const handleFetch = async () => {
    setLoading(true);
    try {
      const fetchEndpointURL = getEndpointURL('structured_webscraper/fetch');
      const fastApiURL = getFastApiURL('structured_webscraper/fetch');
      console.log('fastApiURL: ', fastApiURL);
      const response = await fetch(fetchEndpointURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ url: inputValue, fastapi_url: fastApiURL }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      onFetch(data.html, inputValue);
    } catch (error) {
      console.error('Error fetching HTML:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="url-input-container">
      <label htmlFor="url-input" className="sr-only">URL Input</label>
      <div className="url-input-wrapper">
        <input
          type="text"
          id="url-input"
          name="url-input"
          className="url-input"
          placeholder="Enter URL"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div className="icon-container">
          <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="11" cy="11" r="8"></circle>
            <path d="m21 21-4.3-4.3"></path>
          </svg>
        </div>
        <button
          type="button"
          className="search-button"
          onClick={handleFetch}
          disabled={loading}
        >
          {loading ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z" opacity="0.5" />
              <path fill="currentColor" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite" to="360 12 12" type="rotate" />
              </path>
            </svg>
          ) : "Search"}
        </button>
      </div>
    </div>
  );
});
