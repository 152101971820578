import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './ScrapeTable.css';

export const ScrapeTable = ({ columns, rows }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const formattedColumns = columns.map((col) => ({
    field: col,
    headerName: col.charAt(0).toUpperCase() + col.slice(1),
    width: 150, // Adjust width as necessary
  }));

  // Assuming rows may not have an 'id', we can use the index as a fallback for unique identification
  const getRowId = (row) => row.id || `${row.name}-${row.index}`;

  return (
    <div className="data-grid-container">
      <DataGrid
        className="custom-data-grid"
        rows={rows.map((row, index) => ({ ...row, id: row.id || index }))}
        columns={formattedColumns}
        getRowId={getRowId}
        autoHeight={false}
        disableExtendRowFullWidth
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 50, 100]}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
        }}
        components={{
          Footer: () => <div className="data-grid-footer">Custom Footer Content</div>,
        }}
        style={{ height: '100%', flexGrow: 1 }}
      />
    </div>
  );
};
