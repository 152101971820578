// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { clearLocalStorageOnPageReload, constructEndpointURL, constructFastApiURL, isLocalhost } from './auth_utils';


const AuthContext = createContext();
const LOCAL_DEFAULT_MODE = 'aws';
const DEFAULT_MODE = isLocalhost() ? LOCAL_DEFAULT_MODE : 'aws';
const LOCAL_DJANGO_BASE_URL = 'http://127.0.0.1:8000';
const AWS_DJANGO_BASE_URL = 'http://13.57.188.164:8000';
const LOCAL_FASTAPI_BASE_URL = 'http://driver:8001';
const AWS_FASTAPI_BASE_URL = 'http://54.215.130.159:8001';
const DEFAULT_CLIENT = 'user';
const DEFAULT_AUTHENTICATED = false;

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(DEFAULT_AUTHENTICATED);
    const [client, setClient] = useState(DEFAULT_CLIENT); // Default client; adjust as needed
    const [apiMode, setApiMode] = useState(DEFAULT_MODE); // 'local' or 'aws'

    // Initialize endpointModes from localStorage or default to 'aws'
    const [endpointModes, setEndpointModes] = useState(() => {
        const storedModes = localStorage.getItem('endpointModes');
        console.log(`Stored modes:`, storedModes);
        console.log(`Default mode:`, DEFAULT_MODE);
        // console.log(`isLocalhost:`, isLocalhost());
        return storedModes ? JSON.parse(storedModes) : {
            'structured_webscraper/fetch': DEFAULT_MODE,
            'structured_webscraper/start_scrape': DEFAULT_MODE,
            'structured_webscraper/get_scrape_updates': DEFAULT_MODE,
            'structured_webscraper/get_csv': DEFAULT_MODE,
            'users/signup': DEFAULT_MODE,
            'users/login': DEFAULT_MODE,
            'users/activate': DEFAULT_MODE,
        };
    });

    useEffect(() => { // Persist endpointModes to localStorage whenever the endpointModes state changes
        console.log(`Persisting endpointModes to localStorage`);
        localStorage.setItem('endpointModes', JSON.stringify(endpointModes));
    }, [endpointModes]);

    useEffect(() => { // Clear localStorage and call the kill endpoint on page reload
        clearLocalStorageOnPageReload(setEndpointModes, DEFAULT_MODE);
    }, []);

    const toggleGlobalApiMode = () => { // Function to toggle global API mode
        const newMode = apiMode === 'local' ? 'aws' : 'local';
        console.log(`Toggling global API mode from ${apiMode} to ${newMode}`);
        setApiMode(newMode);
        setEndpointModes(prevModes => // Synchronize all endpoint modes with the new global mode
            Object.keys(prevModes).reduce((acc, endpoint) => {
                acc[endpoint] = newMode;
                return acc;
            }, {})
        );
    };

    const setGlobalApiModeDirectly = (mode) => { // Set global API mode directly based on desired mode
        console.log(`Setting global API mode directly to: ${mode}`);
        setApiMode(mode);
        setEndpointModes(prevModes =>
            Object.keys(prevModes).reduce((acc, endpoint) => {
                acc[endpoint] = mode;
                return acc;
            }, {})
        );
    };

    const toggleEndpointMode = (endpoint) => { // Function to toggle individual endpoint API mode
        setEndpointModes(prevModes => ({
            ...prevModes,
            [endpoint]: prevModes[endpoint] === 'local' ? 'aws' : 'local'
        }));
        console.log(`Toggled API mode for ${endpoint} to ${endpointModes[endpoint] === 'local' ? 'aws' : 'local'}`);
    };

    const getEndpointURL = (endpointName) => constructEndpointURL(endpointName, endpointModes, DEFAULT_MODE, LOCAL_DJANGO_BASE_URL, AWS_DJANGO_BASE_URL);

    const getFastApiURL = (endpointName) => constructFastApiURL(endpointName, endpointModes, DEFAULT_MODE, LOCAL_FASTAPI_BASE_URL, AWS_FASTAPI_BASE_URL);

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            client,
            setClient,
            apiMode,
            setApiMode,
            toggleGlobalApiMode,
            setGlobalApiModeDirectly, // Provide the new function
            getEndpointURL,
            getFastApiURL,
            endpointModes,
            toggleEndpointMode
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
