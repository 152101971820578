

export const clearLocalStorageOnPageReload = (setEndpointModes, DEFAULT_MODE) => { // Clear localStorage and call the kill endpoint on page reload
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') { // Check if the page is hidden, i.e. the user has navigated away from the page
            console.log(`Clearing localStorage`);
            localStorage.removeItem('endpointModes'); // Clear localStorage
        }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
};

export const constructEndpointURL = (endpointName, endpointModes, DEFAULT_MODE, LOCAL_BASE_URL, AWS_DJANGO_BASE_URL) => {
    const currentMode = endpointModes[endpointName] || DEFAULT_MODE;
    const baseUrl = currentMode === 'local' ? LOCAL_BASE_URL : AWS_DJANGO_BASE_URL;
    const endpointURL = `${baseUrl}/${endpointName}`;
    return endpointURL;
};

export const constructFastApiURL = (endpointName, endpointModes, DEFAULT_MODE, LOCAL_BASE_URL, AWS_FASTAPI_URL) => {
    const currentMode = endpointModes[endpointName] || DEFAULT_MODE;
    const fastApiUrl = currentMode === 'local' ? LOCAL_BASE_URL : AWS_FASTAPI_URL;
    return fastApiUrl;
};

export const isLocalhost = () => {
    const isLocalhost = window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost';
    console.log(`window.location.hostname: ${window.location.hostname}`);
    console.log(`isLocalhost: ${isLocalhost}`);
    return isLocalhost;
};

