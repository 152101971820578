import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

export const ShadowDomWrapper = forwardRef(({ htmlContent }, ref) => {
    const shadowRootRef = useRef(null);

    // console.log('ShadowDomWrapper rendered with selectionMode:', selectionMode);

    useImperativeHandle(ref, () => ({
        getShadowDomHtml: () => {
            if (shadowRootRef.current && shadowRootRef.current.shadowRoot) {
                return shadowRootRef.current.shadowRoot.innerHTML;
            }
            return '';
        }
    }));


    useEffect(() => {
        if (shadowRootRef.current) {
            try {
                let shadowRoot = shadowRootRef.current.shadowRoot || shadowRootRef.current.attachShadow({ mode: 'open' });
                if (shadowRoot.innerHTML !== htmlContent) {
                    shadowRoot.innerHTML = htmlContent;

                    shadowRoot.querySelectorAll('script').forEach(script => {
                        try {
                            if (script.innerHTML.includes('P.when')) {
                                eval(script.innerHTML);
                            }
                        } catch (error) {
                            if (error.message.includes('P.when is not a function') || error.message.includes('P is not defined')) {
                                console.warn("Ignoring 'P.when is not a function' or 'P is not defined' error:", error.message);
                            } else {
                                throw error;
                            }
                        }
                    });
                }
            } catch (error) {
                console.error('Error injecting HTML content:', error);
            }
        }
    }, [htmlContent]);

    return <div ref={shadowRootRef} />;
});
