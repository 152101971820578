import React from 'react';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import './Actions.css';
import { useAuth } from '../../../context/AuthContext';

export const Actions = () => {
    const { getEndpointURL } = useAuth();
    const exportToCSV = async () => {
        try {
            // Make a POST request to your backend to get the CSV
            const endpointURL = getEndpointURL('structured_webscraper/get_csv');
            const response = await fetch(endpointURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Extract the filename from the response headers
                const contentDisposition = response.headers.get('Content-Disposition');
                const filename = contentDisposition?.split('filename=')[1] || 'export.csv';

                // Convert the response to a Blob
                const blob = await response.blob();

                // Create a download link and trigger it
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove(); // Remove the link after triggering download
                window.URL.revokeObjectURL(url); // Clean up the object URL
            } else {
                console.error('Failed to export CSV');
            }
        } catch (error) {
            console.error('Error while exporting CSV:', error);
        }
    };

    return (
        <div className="product-table-actions-container">
            <div
                onClick={exportToCSV} // Trigger CSV export on click
                className="action-button"
            >
                <SaveAltOutlinedIcon />
                <span>Export to CSV</span>
            </div>
        </div>
    );
};


// import { FilterModal } from '../filter-modal/FilterModal';
{/* {filterOpen && (
    <FilterModal
        open={filterOpen}
        onClose={handleFilterClose}
        onApply={handleFilterApply}
        columns={Object.keys(initialProducts[0])}
        products={initialProducts}
    />
)} */}