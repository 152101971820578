import React, { useRef, useState } from 'react';
import { Header } from '../components/new-scrape/header/Header';
import { URLInput } from '../components/new-scrape/url-input/URLInput';
import { Browser } from '../components/new-scrape/browser/Browser';
import { ScrapeButton } from '../components/new-scrape/scrape-button/ScrapeButton';
// import { ProductTableContainer } from '../components/new-scrape/product-table-container/ProductTableContainer';
import { ScrapeTable } from '../components/new-scrape/scrape-table/ScrapeTable';
import { Actions } from '../components/new-scrape/actions/Actions';
import './NewScrape.css';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong. Please try again later.</h1>;
        }
        return this.props.children;
    }
}

export const NewScrape = () => {
    const browserRef = useRef();
    const scrapeButtonRef = useRef();
    const [url, setUrl] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState('');

    // State for columns and rows
    const [columns, setColumns] = useState(['scrape to collect']);
    const [rows, setRows] = useState([]);

    const handleScrape = (fields, card_list, isInitialScrape = false) => {
        console.log('fields:', fields, 'card_list:', card_list);

        if (isInitialScrape) {
            setColumns(fields);
            setRows(card_list); // Reset rows to the new card list
        } else {
            // Append the new rows to the existing ones
            setRows(prevRows => [...prevRows, ...card_list]);
        }
    };

    const handleUrlFetch = (fetchedHtml, fetchedUrl) => {
        console.log('Fetched HTML from URL:', fetchedUrl);
        setContent(fetchedHtml);
        setUrl(fetchedUrl);
        setError('');
    };

    return (
        <div className="app-container">
            <Header />

            <div className="mb-4">
                <h2 className="main-title">1. Enter a web page URL to scrape from</h2>
                <URLInput
                    value={url}
                    onFetch={handleUrlFetch}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <div className="flex space-x-2 mt-2">
                    {/* {previousSearches.map((search, index) => (
                        <button key={index} className="py-2 px-4 bg-gray-200 rounded hover:bg-gray-300">{search}</button>
                    ))}
                    <button className="py-2 px-4 bg-gray-200 rounded hover:bg-gray-300">+ create new</button> */}
                </div>
            </div>

            <div className="mb-4">
                <h2 className="main-title mb-4">2. Make sure everything looks right:</h2>
                <div className="bg-white rounded shadow">
                    <div>
                        <ErrorBoundary>
                            <Browser
                                htmlContent={content}
                                ref={browserRef}
                            />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <h2 className="main-title mb-4">3. Choose # of rows and click scrape:</h2>

            <ScrapeButton ref={scrapeButtonRef} onScrape={handleScrape} url={url} />
            
            <h2 className="main-title">4. Review your scraped data:</h2>
            <div className="table-wrapper">
                <ScrapeTable
                    columns={columns}
                    rows={rows.map((row, index) => ({ ...row, id: row.id || index }))}
                />
            </div>

            <h2 className="main-title">5. Actions</h2>
            <Actions />
        </div>
    );
};
