import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './ScrapeButton.css';
import { useAuth } from '../../../context/AuthContext';

export const ScrapeButton = forwardRef(({ onScrape, url }, ref) => {
  const [rows, setRows] = useState(10); // State to manage dropdown selection
  const [customRows, setCustomRows] = useState(10); // State to manage actual number of rows
  const [cardList, setCardList] = useState([]); // State to store the accumulated card list
  const [isScraping, setIsScraping] = useState(false); // State to prevent multiple scrapes at once
  const { getEndpointURL, getFastApiURL } = useAuth();
  useImperativeHandle(ref, () => ({
    getUrl: () => url,
  }));

  const handleScrape = async () => {
    if (isScraping) return;
    setIsScraping(true);

    try {
      console.log('Fetching HTML from:', url);
      const startScrapeEndpointURL = getEndpointURL('structured_webscraper/start_scrape');
      const fastApiURL = getFastApiURL('structured_webscraper/start_scrape');
      console.log('startScrapeEndpointURL: ', startScrapeEndpointURL);

      const response = await fetch(startScrapeEndpointURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ url, num_rows: customRows, fastapi_url: fastApiURL }),
      });


      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Initial fields:', data['fields'], 'Initial card_list:', data['card_list']);
      setCardList(data['card_list']);
      onScrape(data['fields'], data['card_list'], true); // Call the onScrape callback with initial data, passing true for initial scrape

      // Now that the scraping has started, keep fetching updates
      await fetchScrapeUpdates();
    } catch (error) {
      console.error('Error fetching HTML:', error);
    } finally {
      setIsScraping(false);
    }
  };

  const fetchScrapeUpdates = async () => {
    try {
      let hasEnded = false;
      const getScrapeUpdatesEndpointURL = getEndpointURL('structured_webscraper/get_scrape_updates');
      const fastApiURL = getFastApiURL('structured_webscraper/get_scrape_updates');

      while (!hasEnded) {
        const updateResponse = await fetch(getScrapeUpdatesEndpointURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify({ url, fastapi_url: fastApiURL }),
        });

        if (!updateResponse.ok) {
          throw new Error(`HTTP error! status: ${updateResponse.status}`);
        }

        const updateData = await updateResponse.json();
        hasEnded = updateData.has_ended;
        console.log('Scrape update - has_ended:', hasEnded, 'card_list:', updateData.card_list);

        // Append the new card_list to the existing one
        setCardList((prevCardList) => [...prevCardList, ...updateData.card_list]);
        onScrape([], updateData.card_list); // Append new data to existing rows

        // If scraping is still in progress, wait for a short delay before fetching the next update
        if (!hasEnded) {
          await new Promise((resolve) => setTimeout(resolve, 20000)); // 2-second delay between updates
        }
      }
    } catch (error) {
      console.error('Error fetching scrape updates:', error);
    }
  };

  const handleRowsChange = (e) => {
    const value = e.target.value;
    if (value !== 'custom') {
      const numberValue = Number(value);
      setRows(numberValue); // Collapse dropdown to this value
      setCustomRows(numberValue); // Update actual rows
    }
  };

  const handleCustomRowsChange = (e) => {
    const value = Number(e.target.value);
    setCustomRows(value); // Update actual rows count
  };

  return (
    <div>
      <div className="scrape-container">
        <div className="dropdown">
          <select id="rows-select" value={rows === 'custom' ? 'custom' : ''} onChange={handleRowsChange}>
            <option value="" disabled></option>
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value="custom">Custom</option>
          </select>
          <input
            type="number"
            value={customRows}
            onChange={handleCustomRowsChange}
            placeholder="Enter custom number"
          />
        </div>

        <button
          type="button"
          className="scrape-button"
          onClick={handleScrape}
          disabled={isScraping} // Disable the button if scraping is in progress
        >
          {isScraping ? 'Scraping...' : 'Scrape'}
        </button>
      </div>
    </div>
  );
});
