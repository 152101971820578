import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const EmailConfirmation = () => {
  const [status, setStatus] = useState('Verifying your email...');
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const { setIsAuthenticated, endpointModes } = useAuth();

  useEffect(() => {
    const confirmEmail = async () => {
      if (!uidb64 || !token) {
        setStatus('Invalid confirmation link.');
        return;
      }

      try {
        const endpointName = 'users/activate';
        const activateMode = endpointModes[endpointName] || 'local';
        const baseURL = activateMode === 'local' 
          ? 'http://127.0.0.1:8000/'
          : 'http://3.101.21.162:8000/';
        
        const response = await axios.get(`${baseURL}${endpointName}/${uidb64}/${token}/`);

        if (response.data.success) {
          console.log("Email confirmed successfully!");
          setStatus('Email confirmed successfully!');
          alert("Email confirmed successfully! You will be redirected to the home page shortly.");
          setTimeout(() => {
            setIsAuthenticated(true);
            navigate('/'); // Redirect to home page after 3 seconds
          }, 3000);
          console.log("redirecting to home page")
        } else {
          setStatus('Email confirmation failed. Please try again.');
        }
      } catch (error) {
        console.error('Error during email confirmation:', error);
        setStatus('An error occurred. Please try again later.');
      }
    };

    confirmEmail();
  }, [uidb64, token, navigate, setIsAuthenticated, endpointModes]);

  return (
    <div className="email-confirmation-container">
      <h1>Email Confirmation</h1>
      <p>{status}</p>
    </div>
  );
};

export default EmailConfirmation;
