import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, MenuItem, Button, Chip, Popover, List, ListItem, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './CLAdmin.css';

const memberTableColumns = (onRoleChange) => [
    { field: 'memberName', headerName: 'Member Name', width: 200 },
    {
        field: 'role',
        headerName: 'Role',
        width: 250,
        renderCell: (params) => (
            <TextField
                select
                value={params.value}
                size="small"
                variant="outlined"
                style={{
                    width: '150px',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                onChange={(e) => onRoleChange(params.id, e.target.value)}
            >
                {['User', 'Prospero', 'Developer', 'Admin'].map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params) => (
            <Chip
                label={params.value}
                style={{
                    backgroundColor: 'transparent',
                    color:
                        params.value === 'Active'
                            ? '#4caf50'
                            : params.value === 'Deactivated'
                                ? '#f44336'
                                : '#ff9800',
                    fontSize: '14px',
                }}
            />
        ),
    },
    { field: 'lastUsed', headerName: 'Last Used', width: 200 },
    {
        field: 'usage',
        headerName: 'Usage',
        width: 200,
        renderCell: (params) => (
            <div>{`${params.value.tokensUsed}/${params.value.totalTokens} tokens`}</div>
        ),
    },
];

const initialRows = [
    { id: 1, memberName: 'Alice Johnson', role: 'Admin', status: 'Active', lastUsed: '1 month ago', usage: { tokensUsed: 80, totalTokens: 120 }, email: 'alice@example.com' },
    { id: 2, memberName: 'Bob Smith', role: 'User', status: 'Deactivated', lastUsed: '3 months ago', usage: { tokensUsed: 50, totalTokens: 150 }, email: 'bob@example.com' },
    { id: 3, memberName: 'Carol King', role: 'Developer', status: 'Invite Sent', lastUsed: '2 weeks ago', usage: { tokensUsed: 90, totalTokens: 120 }, email: 'carol@example.com' },
    { id: 4, memberName: 'David Wright', role: 'Prospero', status: 'Active', lastUsed: '5 days ago', usage: { tokensUsed: 110, totalTokens: 200 }, email: 'david@example.com' },
];

export const CLAdmin = () => {
    const [rows, setRows] = useState(initialRows);
    const [selectedRowId, setSelectedRowId] = useState(null); // Keep track of selected row
    const [anchorEl, setAnchorEl] = useState(null); // Popover anchor element
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [filterApplied, setFilterApplied] = useState(false);

    const handleRoleChange = (id, newRole) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === id ? { ...row, role: newRole } : row))
        );
    };

    const handleApplyFilter = () => {
        setFilterApplied(true);
    };

    const filteredRows = rows.filter((row) =>
        filterApplied && selectedAttribute
            ? row[selectedAttribute]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            : true
    );

    // Handle actions button click (show popover)
    const handleActionClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close the popover
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    // Handle selection change (set selected row id)
    const handleSelectionChange = (newSelection) => {
        console.log("Selection Changed:", newSelection); // Debug log to check selection changes
        if (newSelection.length > 0) {
            setSelectedRowId(newSelection[0]); // Only allow selecting one row
        } else {
            setSelectedRowId(null);
        }
    };

    // Copy email to clipboard
    const handleCopyEmail = () => {
        if (selectedRowId) {
            const selectedRow = rows.find((row) => row.id === selectedRowId);
            if (selectedRow?.email) {
                navigator.clipboard.writeText(selectedRow.email);
                alert(`Copied email: ${selectedRow.email}`);
            }
        }
        handlePopoverClose();
    };

    // Remove selected row
    const handleRemoveRow = () => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== selectedRowId));
        setSelectedRowId(null);
        handlePopoverClose();
    };

    const open = Boolean(anchorEl);

    return (
        <div className='cladmin-container'>
            <div className="member-data-grid-container">
                <div className="member-table-controls">
                    <div className="member-filter-section">
                        <TextField
                            label="Search"
                            placeholder="Name, status, etc..."
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="member-search-field"
                        />
                        <TextField
                            select
                            label="Attribute"
                            variant="outlined"
                            size="small"
                            value={selectedAttribute}
                            onChange={(e) => setSelectedAttribute(e.target.value)}
                            className="member-attribute-field"
                            style={{ width: '150px' }}
                        >
                            {memberTableColumns(handleRoleChange).map((column) => (
                                <MenuItem key={column.field} value={column.field}>
                                    {column.headerName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            variant="outlined"
                            endIcon={<FilterListIcon />}
                            className="member-filter-button"
                            onClick={handleApplyFilter}
                        >
                            Filter
                        </Button>
                    </div>
                    <div className="member-invite-controls">
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<AddOutlinedIcon />}
                            className="invite-button"
                            style={{
                                backgroundColor: '#FFFFFF',
                                border: '1.5px solid #000000',
                                color: '#000000',
                                boxShadow: 'none',
                            }}
                        >
                            INVITE USER
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className="export-button"
                            onClick={handleActionClick} // Open popover
                            disabled={!selectedRowId} // Disable if no row selected
                        >
                            ACTIONS
                        </Button>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className='action-items'>
                                <div onClick={handleCopyEmail}>
                                    <div className='action-item'>
                                        <ContentCopyOutlinedIcon style={{ width: '20px' }} />
                                        <span>Copy Email</span>
                                    </div>
                                </div>
                                <div onClick={handleRemoveRow}>
                                    <div className='action-item'>
                                        <DeleteOutlineOutlinedIcon style={{ width: '24px' }} />
                                        <span>Remove</span>
                                    </div>
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>

                <DataGrid
                    rows={filteredRows}
                    columns={memberTableColumns(handleRoleChange)}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                    onRowSelectionModelChange={handleSelectionChange} // Track selected row
                    className="member-data-grid"
                />
            </div>
        </div>
    );
};
