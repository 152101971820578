import React, { useState } from 'react';
import './ChatbotPage.css';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

const initialData = [
    {
        id: 1,
        name: 'Example Chatbot 1',
        systemPrompt: 'This is an example system prompt for chatbot 1',
        tools: [
            { icon: <LanguageOutlinedIcon />, name: 'Web Search', description: 'Web search prompt for chatbot 1', enabled: true },
            { icon: <TerminalOutlinedIcon />, name: 'Structured Data Search', description: 'Structured data search for chatbot 1', enabled: true },
            { icon: <ShareOutlinedIcon />, name: 'Concept Search', description: 'Concept search for chatbot 1', enabled: false },
        ],
        scrapedData: [
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 1', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: true },
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 2', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 3', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: true },
            // { icon: <LinkOutlinedIcon />, name: 'Scraped Data 4', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
        ],
        concepts: [
            { icon: <LinkOutlinedIcon />, name: 'Concept DB 1', description: 'This is an example of a description for a concept data source xxxxx xxxx xxxx xxxx', enabled: true },
            { icon: <LinkOutlinedIcon />, name: 'Concept DB 2', description: 'This is an example of a description for a concept data source xxxxx xxxx xxxx xxxx', enabled: false },
        ],
    },
    {
        id: 2,
        name: 'Example Chatbot 2',
        systemPrompt: 'This is an example system prompt for chatbot 2',
        tools: [
            { icon: <LanguageOutlinedIcon />, name: 'Web Search', description: 'Web search prompt for chatbot 1', enabled: true },
            { icon: <TerminalOutlinedIcon />, name: 'Structured Data Search', description: 'Structured data search for chatbot 1', enabled: false },
            { icon: <ShareOutlinedIcon />, name: 'Concept Search', description: 'Concept search for chatbot 1', enabled: true },
        ],
        scrapedData: [
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 1', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 2', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
            // { icon: <LinkOutlinedIcon />, name: 'Scraped Data 3', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: true },
            // { icon: <LinkOutlinedIcon />, name: 'Scraped Data 4', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
        ],
        concepts: [
            { icon: <LinkOutlinedIcon />, name: 'Concept DB 1', description: 'This is an example of a description for a concept data source xxxxx xxxx xxxx xxxx', enabled: true },
            { icon: <LinkOutlinedIcon />, name: 'Concept DB 2', description: 'This is an example of a description for a concept data source xxxxx xxxx xxxx xxxx', enabled: false },
        ],
    },
    {
        id: 3,
        name: 'Example Chatbot 3',
        systemPrompt: 'This is an example system prompt for chatbot 3',
        tools: [
            { icon: <LanguageOutlinedIcon />, name: 'Web Search', description: 'Web search prompt for chatbot 1', enabled: true },
            { icon: <TerminalOutlinedIcon />, name: 'Structured Data Search', description: 'Structured data search for chatbot 1', enabled: false },
            { icon: <ShareOutlinedIcon />, name: 'Concept Search', description: 'Concept search for chatbot 1', enabled: false },
        ],
        scrapedData: [
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 1', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 2', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
            { icon: <LinkOutlinedIcon />, name: 'Scraped Data 3', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
            // { icon: <LinkOutlinedIcon />, name: 'Scraped Data 4', description: 'This is an example of a description for a scraped data source xxxxx xxxx xx', enabled: false },
        ],
        concepts: [
            { icon: <LinkOutlinedIcon />, name: 'Concept DB 1', description: 'This is an example of a description for a concept data source xxxxx xxxx xxxx xxxx', enabled: false },
            { icon: <LinkOutlinedIcon />, name: 'Concept DB 2', description: 'This is an example of a description for a concept data source xxxxx xxxx xxxx xxxx', enabled: true },
        ],
    },
];

const ChatbotPage = () => {
    const [chatbots, setChatbots] = useState(initialData);
    const [activeChatbotId, setActiveChatbotId] = useState(chatbots[0].id);
    const [isEditingPrompt, setIsEditingPrompt] = useState(false);
    const [currentPrompt, setCurrentPrompt] = useState(chatbots[0].systemPrompt);
    const [editingToolIndex, setEditingToolIndex] = useState(null);
    const [editingScrapedIndex, setEditingScrapedIndex] = useState(null);
    const [editingConceptIndex, setEditingConceptIndex] = useState(null);
    const [toolDescriptions, setToolDescriptions] = useState({});  // For holding unsaved descriptions during edit

    const handleChatbotSelect = (chatbot) => {
        setActiveChatbotId(chatbot.id);
        setCurrentPrompt(chatbot.systemPrompt);
        setIsEditingPrompt(false);
        setEditingToolIndex(null);
        setEditingScrapedIndex(null);
        setEditingConceptIndex(null);
        setToolDescriptions({});  // Clear temp descriptions when switching
    };

    const toggleEnable = (category, index) => {
        setChatbots(chatbots.map(chatbot => {
            if (chatbot.id === activeChatbotId) {
                const updatedCategory = chatbot[category].map((item, idx) =>
                    idx === index ? { ...item, enabled: !item.enabled } : item
                );
                return { ...chatbot, [category]: updatedCategory };
            }
            return chatbot;
        }));
    };

    const handleSaveDescription = (category, index) => {
        if (category === 'tools') {
            const newDescription = toolDescriptions[index] || activeChatbot.tools[index].description;
            setChatbots(chatbots.map(chatbot => {
                if (chatbot.id === activeChatbotId) {
                    const updatedTools = chatbot.tools.map((tool, idx) =>
                        idx === index ? { ...tool, description: newDescription } : tool
                    );
                    return { ...chatbot, tools: updatedTools };
                }
                return chatbot;
            }));
            setEditingToolIndex(null);
        }
        if (category === 'scrapedData') {
            const newDescription = toolDescriptions[index] || activeChatbot.scrapedData[index].description;
            setChatbots(chatbots.map(chatbot => {
                if (chatbot.id === activeChatbotId) {
                    const updatedScrapedData = chatbot.scrapedData.map((data, idx) =>
                        idx === index ? { ...data, description: newDescription } : data
                    );
                    return { ...chatbot, scrapedData: updatedScrapedData };
                }
                return chatbot;
            }));
            setEditingScrapedIndex(null);
        }
        if (category === 'concepts') {
            const newDescription = toolDescriptions[index] || activeChatbot.concepts[index].description;
            setChatbots(chatbots.map(chatbot => {
                if (chatbot.id === activeChatbotId) {
                    const updatedConcepts = chatbot.concepts.map((concept, idx) =>
                        idx === index ? { ...concept, description: newDescription } : concept
                    );
                    return { ...chatbot, concepts: updatedConcepts };
                }
                return chatbot;
            }));
            setEditingConceptIndex(null);
        }
    };

    const activeChatbot = chatbots.find(chatbot => chatbot.id === activeChatbotId);

    return (
        <div className="chatbot-page">
            <div className="chatbot-sidebar">
                <h2>Example Chatbots</h2>
                {chatbots.map((chatbot) => (
                    <div
                        key={chatbot.id}
                        className={`chatbot-item ${chatbot.id === activeChatbotId ? 'active' : ''}`}
                        onClick={() => handleChatbotSelect(chatbot)}
                    >
                        {chatbot.name}
                    </div>
                ))}
            </div>

            <div className="chatbot-details">
                {/* System Prompt */}
                <div className="chatbot-section">
                    <div className="chatbot-section-header">
                        <h3>System Prompt</h3>
                        <button className="edit-button" onClick={() => setIsEditingPrompt(!isEditingPrompt)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M7.0002 3.67102H4.2002C3.08009 3.67102 2.51962 3.67102 2.0918 3.88901C1.71547 4.08075 1.40973 4.38649 1.21799 4.76282C1 5.19064 1 5.75111 1 6.87122V16.4712C1 17.5913 1 18.1511 1.21799 18.5789C1.40973 18.9552 1.71547 19.2615 2.0918 19.4532C2.5192 19.671 3.07899 19.671 4.19691 19.671H13.8031C14.921 19.671 15.48 19.671 15.9074 19.4532C16.2837 19.2615 16.5905 18.9549 16.7822 18.5786C17 18.1512 17 17.592 17 16.4741V13.671M13 4.67102L7 10.671V13.671H10L16 7.67102M13 4.67102L16 1.67102L19 4.67102L16 7.67102M13 4.67102L16 7.67102"
                                    stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className="prompt-content">
                        {isEditingPrompt ? (
                            <textarea
                                className="custom-textarea"
                                rows={4}
                                value={currentPrompt}
                                onChange={(e) => setCurrentPrompt(e.target.value)}
                            />
                        ) : (
                            <p>{currentPrompt}</p>
                        )}
                    </div>
                </div>

                {/* Tools chatbot-section */}
                <div className="chatbot-section">
                    <div className="chatbot-section-header">
                        <h3>Tools</h3>
                    </div>
                    <div className="chatbot-section-tools">
                        {activeChatbot.tools.map((tool, index) => (
                            <div key={index} className="tool-item">
                                <div className="tool-item-header">
                                    <div className="tool-item-header-title">
                                        {tool.icon}<span>{tool.name}</span>
                                    </div>
                                    <div className="tool-item-header-buttons">
                                        <label className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                checked={tool.enabled}
                                                onChange={() => toggleEnable('tools', index)}
                                            />
                                            <span className="chatbot-slider"></span>
                                        </label>
                                        <button
                                            className="edit-button"
                                            onClick={() => {
                                                if (editingToolIndex === index) {
                                                    handleSaveDescription('tools', index);
                                                } else {
                                                    setEditingToolIndex(index);
                                                }
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                <path d="M7.0002 3.67102H4.2002C3.08009 3.67102 2.51962 3.67102 2.0918 3.88901C1.71547 4.08075 1.40973 4.38649 1.21799 4.76282C1 5.19064 1 5.75111 1 6.87122V16.4712C1 17.5913 1 18.1511 1.21799 18.5789C1.40973 18.9552 1.71547 19.2615 2.0918 19.4532C2.5192 19.671 3.07899 19.671 4.19691 19.671H13.8031C14.921 19.671 15.48 19.671 15.9074 19.4532C16.2837 19.2615 16.5905 18.9549 16.7822 18.5786C17 18.1512 17 17.592 17 16.4741V13.671M13 4.67102L7 10.671V13.671H10L16 7.67102M13 4.67102L16 1.67102L19 4.67102L16 7.67102M13 4.67102L16 7.67102"
                                                    stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="tool-item-content">
                                    {editingToolIndex === index ? (
                                        <textarea
                                            className="custom-textarea"
                                            value={toolDescriptions[index] || tool.description}
                                            rows={3}
                                            onChange={(e) => setToolDescriptions({ ...toolDescriptions, [index]: e.target.value })}
                                        />
                                    ) : (
                                        <p>{tool.description}</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Scraped Data chatbot-section */}
                <div className="chatbot-section">
                    <div className="chatbot-section-header">
                        <h3>Scraped Data</h3>
                    </div>
                    <div className="chatbot-section-scraper-tools">
                        <div className="chatbot-section-scraper">
                            {activeChatbot.scrapedData.map((data, index) => (
                                <div key={index} className="scraper-item">
                                    <div className="scraper-item-header">
                                        <div className="tool-item-header-title">
                                            {data.icon}<span>{data.name}</span>
                                        </div>
                                        <label className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                checked={data.enabled}
                                                onChange={() => toggleEnable('scrapedData', index)}
                                            />
                                            <span className="chatbot-slider"></span>
                                        </label>
                                    </div>

                                    {editingScrapedIndex === index ? (
                                        <textarea
                                            className="custom-textarea"
                                            value={toolDescriptions[index] || data.description}
                                            onChange={(e) => setToolDescriptions({ ...toolDescriptions, [index]: e.target.value })}
                                        />
                                    ) : (
                                        <p>{data.description}</p>
                                    )}

                                </div>
                            ))}
                        </div>
                    </div>

                </div>

                {/* Concepts chatbot-section */}
                <div className="chatbot-section">
                    <div className="chatbot-section-header">
                        <h3>Concepts</h3>
                    </div>
                    <div className="chatbot-section-scraper-tools">
                        <div className="chatbot-section-concepts">
                            {activeChatbot.concepts.map((concept, index) => (
                                <div key={index} className="concepts-item">
                                    <div className="scraper-item-header">
                                        <div className="tool-item-header-title">
                                            {concept.icon}<span>{concept.name}</span>
                                        </div>
                                        <label className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                checked={concept.enabled}
                                                onChange={() => toggleEnable('concepts', index)}
                                            />
                                            <span className="chatbot-slider"></span>
                                        </label>
                                    </div>
                                    {editingConceptIndex === index ? (
                                        <textarea
                                            className="custom-textarea"
                                            value={toolDescriptions[index] || concept.description}
                                            onChange={(e) => setToolDescriptions({ ...toolDescriptions, [index]: e.target.value })}
                                        />
                                    ) : (
                                        <p>{concept.description}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatbotPage;
