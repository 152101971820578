// src/pages/LogIn.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './SignUp.css'; // Import the CSS file
import Logo from '../assets/logo.png';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const LogIn = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setIsAuthenticated, setClient, getEndpointURL } = useAuth();


  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const isFormEmpty = formik.values.email === '' || formik.values.password === '';

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting login form ');
    const loginURL = getEndpointURL('users/login');
    console.log('loginURL: ', loginURL);
    try {
      const response = await fetch(loginURL, {
        method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: formik.values.email, password: formik.values.password }),
      });
      if (response.ok) {
        alert('Login successful!');
        setIsAuthenticated(true);
        const data = await response.json();
        localStorage.setItem('authToken', data.token);
  
        console.log("fetched client: ", data['client'], "\nwith token: ", data['token']);
        setClient(data['client']);
        navigate('/'); // Redirect to the scrape page
      } else {
        alert('Login failed!');
      }
    } catch (error) {
      console.error('Error logging in');
      alert('Error logging in');
    }
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <div className="container">
      <div className="title-container">
        <img src={Logo} alt="logo" />
        Data Shark
      </div>
      <h1>Gather data & insights from anywhere on the internet</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email *</label>
          <input
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={formik.touched.email && formik.errors.email ? 'input-error' : ''}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>
        <div>
          <label>Password *</label>
          <input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={formik.touched.password && formik.errors.password ? 'input-error' : ''}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="error">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="submit-container">
          <p>
            By clicking this button, you agree to our Terms of Service and Privacy Policy
          </p>
          <button className='submit-button' disabled={!formik.isValid || isFormEmpty || loading} onClick={handleSubmit}>
            {loading ? 'Signing In...' : 'Sign In'}
          </button>
        </div>
      </form>
      <div className="login-container">
        Don't have an account?
        <button className="login-button" onClick={handleSignUp}>Sign Up</button>
      </div>


    </div>
  );
};

export default LogIn;
