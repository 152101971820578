import React, { useState } from 'react';
import './ConceptsPage.css';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

const initialData = [
    {
        id: 1,
        name: 'Example Concept 1',
        systemPrompt: 'This is an example system prompt for concept 1',
        websites: [
            { id: 1, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: true },
            { id: 2, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: true },
            { id: 3, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: true },
            { id: 4, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: true },
            { id: 5, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: true },
        ],
        concepts: [
            { id: 1, type: 'Fact', description: 'This is an example concept description', source: 'https://www.amazon.com/s?k=cauliflower&i=amazonfresh&crid=1R2RPM6ZUU6GB&sprefix=%2Camazonfresh%2C125&ref=nb_sb_ss_recent_1_0_recent', enabled: true },
            { id: 2, type: 'Fact', description: 'This is another concept description', source: 'https://www.amazon.com/s?k=cauliflower&i=amazonfresh&crid=1R2RPM6ZUU6GB&sprefix=%2Camazonfresh%2C125&ref=nb_sb_ss_recent_1_0_recent', enabled: false },
        ],
    },
    {
        id: 2,
        name: 'Example Concept 2',
        systemPrompt: 'This is an example system prompt for concept 2',
        websites: [
            { id: 1, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: true },
            { id: 2, url: 'Amazon.com : cauliflower', description: 'This is an example url description', enabled: false },
        ],
        concepts: [
            { id: 1, type: 'Fact', description: 'This is an example concept description', source: 'https://www.amazon.com/s?k=cauliflower&i=amazonfresh&crid=1R2RPM6ZUU6GB&sprefix=%2Camazonfresh%2C125&ref=nb_sb_ss_recent_1_0_recent', enabled: true },
            { id: 2, type: 'Fact', description: 'This is another concept description', source: 'https://www.amazon.com/s?k=cauliflower&i=amazonfresh&crid=1R2RPM6ZUU6GB&sprefix=%2Camazonfresh%2C125&ref=nb_sb_ss_recent_1_0_recent', enabled: false },
        ],
    },
];

const ConceptsPage = () => {
    const [concepts, setConcepts] = useState(initialData);
    const [activeConceptId, setActiveConceptId] = useState(concepts[0].id);
    const [isEditingPrompt, setIsEditingPrompt] = useState(false);
    const [currentPrompt, setCurrentPrompt] = useState(concepts[0].systemPrompt);

    const handleConceptSelect = (concept) => {
        setActiveConceptId(concept.id);
        setCurrentPrompt(concept.systemPrompt);
        setIsEditingPrompt(false);
    };

    // Handle the toggle for the "websites" enabled column
    const handleWebsiteToggle = (id) => {
        setConcepts(concepts.map(concept => {
            if (concept.id === activeConceptId) {
                const updatedWebsites = concept.websites.map(website =>
                    website.id === id ? { ...website, enabled: !website.enabled } : website
                );
                return { ...concept, websites: updatedWebsites };
            }
            return concept;
        }));
    };

    // Handle the toggle for the "concepts" enabled column
    const handleConceptToggle = (id) => {
        setConcepts(concepts.map(concept => {
            if (concept.id === activeConceptId) {
                const updatedConcepts = concept.concepts.map(c =>
                    c.id === id ? { ...c, enabled: !c.enabled } : c
                );
                return { ...concept, concepts: updatedConcepts };
            }
            return concept;
        }));
    };

    const activeConcept = concepts.find(concept => concept.id === activeConceptId);

    const websiteColumns = [
        { field: 'url', headerName: 'URL', width: 200 },
        { field: 'description', headerName: 'Description', width: 250 },
        {
            field: 'enabled',
            headerName: 'Enabled',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <label className="concept-switch">
                        <input
                            type="checkbox"
                            checked={params.value}
                            onChange={() => handleWebsiteToggle(params.id)}
                        />
                        <span className="concept-slider"></span>
                    </label>
                </div>
            ),
        },
    ];

    const conceptColumns = [
        { field: 'type', headerName: 'Type', width: 80 },
        { field: 'description', headerName: 'Description', width: 250 },
        { field: 'source', headerName: 'Source', width: 200 },
        {
            field: 'enabled',
            headerName: 'Enabled',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <label className="concept-switch">
                        <input
                            type="checkbox"
                            checked={params.value}
                            onChange={() => handleConceptToggle(params.id)}
                        />
                        <span className="concept-slider"></span>
                    </label>
                </div>
            ),
        },
    ];

    return (
        <div className="concepts-page">
            <div className="concepts-sidebar">
                <h2>Example Concepts</h2>
                {concepts.map((concept) => (
                    <div
                        key={concept.id}
                        className={`concept-item ${concept.id === activeConceptId ? 'active' : ''}`}
                        onClick={() => handleConceptSelect(concept)}
                    >
                        {concept.name}
                    </div>
                ))}
            </div>

            <div className="concept-details">
                {/* System Prompt Section */}
                <div className="concept-section">
                    <div className="concept-section-header">
                        <h3>System Prompt</h3>
                        <button className="edit-button" onClick={() => setIsEditingPrompt(!isEditingPrompt)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M7.0002 3.67102H4.2002C3.08009 3.67102 2.51962 3.67102 2.0918 3.88901C1.71547 4.08075 1.40973 4.38649 1.21799 4.76282C1 5.19064 1 5.75111 1 6.87122V16.4712C1 17.5913 1 18.1511 1.21799 18.5789C1.40973 18.9552 1.71547 19.2615 2.0918 19.4532C2.5192 19.671 3.07899 19.671 4.19691 19.671H13.8031C14.921 19.671 15.48 19.671 15.9074 19.4532C16.2837 19.2615 16.5905 18.9549 16.7822 18.5786C17 18.1512 17 17.592 17 16.4741V13.671M13 4.67102L7 10.671V13.671H10L16 7.67102M13 4.67102L16 1.67102L19 4.67102L16 7.67102M13 4.67102L16 7.67102"
                                    stroke="#F9F9F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className="prompt-content">
                        {isEditingPrompt ? (
                            <textarea
                                className="custom-textarea"
                                rows={4}
                                value={currentPrompt}
                                onChange={(e) => setCurrentPrompt(e.target.value)}
                            />
                        ) : (
                            <p>{currentPrompt}</p>
                        )}
                    </div>
                </div>

                {/* Websites Section */}
                <div className="concept-section">
                    <div className="concept-section-header">
                        <h3>Websites</h3>
                    </div>
                    <Paper backgroundColor="#333" height="100%">
                        <DataGrid
                            rows={activeConcept.websites}
                            columns={websiteColumns}
                            pagination
                            pageSizeOptions={[5, 10]}
                            rowHeight={40}
                            autoHeight={true}
                            sx={{
                                border: 0,
                                height: '100%',
                            }}
                        />
                    </Paper>
                </div>

                {/* Concepts Section */}
                <div className="concept-section">
                    <div className="concept-section-header">
                        <h3>Concepts</h3>
                    </div>
                    <Paper backgroundColor="#333" height="100%">
                        <DataGrid
                            rows={activeConcept.concepts}
                            columns={conceptColumns}
                            pagination
                            pageSizeOptions={[5, 10]}
                            rowHeight={40}
                            autoHeight={true}
                            sx={{
                                border: 0,
                                height: '100%',
                            }}
                        />
                    </Paper>
                </div>

            </div>
        </div>
    );
};

export default ConceptsPage;
