import React from 'react';
import './ScraperPage.css';

const ScraperPage = () => {
    return (
        <div className="coming-soon-container">
            <div className="coming-soon-box">
                <svg xmlns="http://www.w3.org/2000/svg" width="104" height="94" viewBox="0 0 104 94" fill="none">
                    <path d="M52 30.8586V52.6085M10.1532 64.5699C5.15958 73.135 2.66349 77.4191 3.03647 80.9332C3.3618 83.9984 4.98633 86.7831 7.50427 88.5947C10.39 90.6709 15.3806 90.6709 25.361 90.6709H78.639C88.6195 90.6709 93.6092 90.6709 96.495 88.5947C99.0129 86.7831 100.638 83.9984 100.964 80.9332C101.337 77.4191 98.8411 73.135 93.8475 64.5699L67.218 18.8951C62.2244 10.3301 59.7265 6.04829 56.4667 4.61109C53.6235 3.3575 50.3747 3.3575 47.5315 4.61109C44.2731 6.04769 41.7767 10.3295 36.7872 18.8874L10.1532 64.5699ZM52.2788 68.921V69.4647L51.7265 69.4658V68.921H52.2788Z" stroke="white" stroke-opacity="0.65" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p>Coming Soon</p>
            </div>
        </div>
    );
};

export default ScraperPage;
