import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NewScrape } from './pages/NewScrape';
import LogIn from './pages/LogIn';
import SignUp from './pages/SignUp';
import { CLAdmin } from './pages/CLAdmin';
import UnderConstruction from './pages/UnderConstruction';
import EmailConfirmation from './pages/EmailConfirmation';
import { Sidebar } from './components/sidebar/Sidebar';
import 'react-resizable/css/styles.css';
import './App.css';
import './index.css';
import ConsoleMenu from './components/console-menu/ConsoleMenu';
import { isLocalhost } from './context/auth_utils';

import { useAuth } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  const { client } = useAuth();
  const [showConsoleMenu, setShowConsoleMenu] = useState(isLocalhost());

  // useEffect(() => {
  //   const handleTabClose = (event) => {
  //     // Define your API endpoint
  //     axios.post('http://127.0.0.1:8000/structured_webscraper/kill')
  //       .then(() => {
  //         console.log("Backend stopped successfully!");
  //       })
  //       .catch((error) => {
  //         console.error("There was an error resetting the messages!", error);
  //       });
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);

  return (
    <>
      {showConsoleMenu && <ConsoleMenu/>}
      <Router>
        <Routes>
        <Route path="/login" element={<LogIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/users/activate/:uidb64/:token" element={<EmailConfirmation />} />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              {client === 'dev' && !showConsoleMenu && <ConsoleMenu/>}
              <div className="flex h-screen">
                <div className="flex-grow w-64">
                  <Sidebar />
                </div>
                <div className="flex-grow root-content-container">
                  <Routes>
                    <Route path="/" element={<NewScrape />} />
                    {client === 'dev' ? <Route path="/cladmin" element={<CLAdmin />} /> : <Route path="/cladmin" element={<UnderConstruction />} />}
                  </Routes>
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        </Routes>
      </Router>
    </>
  );
};

export default App;
